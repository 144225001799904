<template>
    <div>
        <div class="container-fluid pr-6 pl-6 mt-5" v-if="resMngt === 'old'">
            <div class="card text-center">
                <div class="card-header d-flex justify-content-start h5 text-white" style="background-color: #444444;">Results Management</div>
                <div class="card-header h6 text-black" style="background-color: #EBEBEB;">
                    <div class="row">
                        <div class="col-4 d-flex justify-content-center">ALL CLIENTS</div>
                        <div class="col-4 d-flex justify-content-center">CLIENT MANAGEMENT</div>
                        <div class="col-4 d-flex justify-content-center">PRODUCT MANAGEMENT</div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-3"><strong class="mr-1">Client:</strong> {{ items.client.Name }}</div>
                        <div class="col-3"><strong class="mr-1">Plan Type:</strong>{{ items.config.workflow.name }}</div>
                        <div class="col-3"><strong class="mr-1">Search Type:</strong>{{ items.config.workflow.type }}</div>
                        <div class="col-3"><strong class="mr-1">Run Time:</strong>{{ items.config.workflow.runTime }}</div>
                        <div class="col-3 mt-2 d-flex justify-content-center"><strong class="mr-1">Search Types:</strong><div v-for="(data, index ) in items.config.steps" :key="data._id">{{ data.widget }}<span class="m-1" v-if="index !== items.config.steps.length - 1"> | </span> </div></div>
                        <div class="col-3 mt-2 pr-0 d-flex justify-content-center"><strong class="mr-1">Min Confidence Score:</strong>{{ items.config.workflow.minScore }}</div>
                        <div class="col-3 mt-2 d-flex justify-content-center"><strong class="mr-1">Max Confidence Score:</strong>{{ items.config.workflow.maxScore }}</div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-6 mt-2 d-flex justify-content-center"><strong class="mr-1">Keywords:</strong><div v-for="(data, index ) in items.config.keywords" :key="data._id">{{ data }}<span class="mr-1" v-if="index !== items.config.keywords.length - 1">, </span> </div></div>
                        <div class="col-6 mt-2 d-flex justify-content-center"><strong class="mr-1">Exclusions:</strong><div v-for="(data, index ) in items.config.exclusions" :key="data._id">{{ data }}<span class="mr-1" v-if="index !== items.config.exclusions.length - 1">, </span> </div></div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-6 mt-2 d-flex justify-content-center"><strong class="mr-1">Search Date Range:</strong>{{ items.config.start_date }}  <strong class="mr-2 ml-2"> to </strong>   {{ items.config.stop_date }}</div>
                        <div class="col-6 mt-2 d-flex justify-content-center"><strong class="mr-1">Automated Action Email:</strong><div v-for="(data, index ) in items.config.actions" :key="data._id">{{ data.action_info.email }}<span class="mr-1" v-if="index !== items.config.actions.length - 1">, </span> </div></div>
                    </div>
                </div>
                <div class="card-header p-2" style="background-color: #AAAAAA;"></div>
                <div class="card-body pl-0 pt-0 pr-0">
                    <b-table
                        class="table table-hover"
                        :items="items.result"
                        :fields="resultFields"
                        :current-page="currentPage">

                        <template v-slot:cell(day)="row">
                            <div>{{ row.item.day.substr(0, 10) }}</div>
                        </template>

                        <template v-slot:cell(actions)="row">
                            <b-button class="btn btn-info btn-sm mr-1" v-b-tooltip.top title="View Details" @click="sendDetails(row.item)"><i class="fa fa-eye" size="sm"/></b-button>
                            <b-button class="btn btn-primary btn-sm" v-b-tooltip.top title="Export" @click="exportDetails(row.item.day, items.config.workflow.name)"><i class="fa fa-file-excel-o" size="sm"/></b-button>
                        </template>

                    </b-table>

<!--                    <div class="col-11 col-md-11 d-flex justify-content-center">-->
<!--                        <b-pagination-->
<!--                            v-model="currentPage"-->
<!--                            :total-rows="rows"-->
<!--                            :per-page="perPage"-->
<!--                        ></b-pagination>-->
<!--                    </div>-->
                </div>
            </div>
        </div >
        <result_details :date="details" :user="user" :steps="items.config.steps" />
<!--        <result :data="row_item" :user="user" />-->
<!--        <div class="container-fluid pl-0 pr-0 mt-6" v-if="resMngt === 'new'">-->
<!--            <div class="card text-center">-->
<!--                <div class="card-header d-flex justify-content-start h5 text-white" style="background-color: #444444;">Results Management</div>-->
<!--                <div class="card-header h6 text-black" style="background-color: #EBEBEB;">-->
<!--                    <div class="row">-->
<!--                        <div class="col-4 d-flex justify-content-center">ALL CLIENTS</div>-->
<!--                        <div class="col-4 d-flex justify-content-center">CLIENT MANAGEMENT</div>-->
<!--                        <div class="col-4 d-flex justify-content-center">PRODUCT MANAGEMENT</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="card-header">-->
<!--                    <div class="row">-->
<!--                        <div class="col-2 d-flex justify-content-center">-->
<!--                            <b-input-group class="" size="sm">-->
<!--                                <b-form-input id="StartDate" type="text" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>-->
<!--                                <b-input-group-append >-->
<!--                                    <b-form-datepicker button-only right locale="en-US" aria-controls="StartDate" size="sm"></b-form-datepicker>-->
<!--                                </b-input-group-append>-->
<!--                            </b-input-group>-->
<!--                        </div>-->
<!--                        <div class="col-2 d-flex justify-content-center">-->
<!--                            <b-input-group class="" size="sm">-->
<!--                                <b-form-input id="StartDate" type="text" placeholder="YYYY-MM-DD" autocomplete="off"></b-form-input>-->
<!--                                <b-input-group-append >-->
<!--                                    <b-form-datepicker button-only right locale="en-US" aria-controls="StartDate" size="sm"></b-form-datepicker>-->
<!--                                </b-input-group-append>-->
<!--                            </b-input-group>-->
<!--                        </div>-->
<!--                        <div class="col-2 d-flex justify-content-center">-->
<!--                            <b-form-select :options="runtimes"></b-form-select>-->
<!--                        </div>-->
<!--                        <div class="col-2 d-flex justify-content-center">-->
<!--                            <b-form-select :options="clientnames"></b-form-select>-->
<!--                        </div>-->
<!--                        <div class="col-1 d-flex justify-content-center">-->
<!--                            <b-form-select :options="productnames"></b-form-select>-->
<!--                        </div>-->
<!--                        <div class="col-2 d-flex justify-content-center">-->
<!--                            <b-form-select :options="plantypes"></b-form-select>-->
<!--                        </div>-->
<!--                        <div class="col-1">-->
<!--                            <b-button v-text="'Search'" variant="dark" />-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="card-header border-bottom-0">-->
<!--                    <div class="container-fluid">-->
<!--                        <div class="row">Client Name: </div>-->
<!--                        <div class="row">Results Generation: </div>-->
<!--                        <div class="row">Date Range: </div>-->
<!--                        <div class="row mt-3">Product: Content Shield</div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="container-fluid mt-4">-->
<!--                    <div class="card">-->
<!--                        <div class="card-header d-flex justify-content-start p-1 text-white" style="background-color: #444444;">Plan 1</div>-->
<!--                        <div class="container-fluid">-->
<!--                            <div class="row">-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Plan Type</strong>-->
<!--                                </div>-->
<!--                                <div class="col-2"></div>-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Search Type</strong>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="row mt-5">-->
<!--                                <div class="col-12 p-2 d-flex justify-content-start"><strong>UNIT CONFIGURATION 01</strong></div>-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Minimum Confidence Score</strong>-->
<!--                                </div>-->
<!--                                <div class="col-2"></div>-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Minimum Passing Score</strong>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Maximum Confidence Score</strong>-->
<!--                                </div>-->
<!--                                <div class="col-2"></div>-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Maximum Passing Score</strong>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>Minimum Fall Action Value</strong>-->
<!--                                </div>-->
<!--                                <div class="col-2"></div>-->
<!--                                <div class="col-2 p-2 d-flex justify-content-start" style="background-color: #EBEBEB">-->
<!--                                    <strong>GA ID</strong>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="row mt-4">-->
<!--                                <b-table-->
<!--                                    class="table table-hover"-->
<!--                                    :items="items"-->
<!--                                    head-variant="dark"-->
<!--                                    :fields="resultFields">-->

<!--                                    <template v-slot:cell(actions)="row">-->
<!--                                        <b-button class="btn btn-translucent-info btn-sm btn-icon" v-b-tooltip.top title="View Details" @click="row_item = row.item" v-b-modal.result-row><font-awesome-icon icon="eye" size="sm"/></b-button>-->
<!--                                    </template>-->

<!--                                </b-table>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <b-pagination></b-pagination>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="container-fluid pl-0 pr-0 mt-6" v-if="resMngt === 'demo'">-->
<!--            <div class="row">-->
<!--                <div class="col-11 col-md-11 d-flex justify-content-center">-->
<!--                    <h2>Content Shield Results</h2>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row d-flex justify-content-center">-->
<!--                <div class="col-11 col-md-11">-->
<!--                    <b-table-->
<!--                        class="table table-hover"-->
<!--                        :items="results"-->
<!--                        :per-page="perPage"-->
<!--                        :current-page="currentPage"-->
<!--                        :fields="demoFields">-->

<!--                        <template v-slot:cell(url)="row">-->
<!--                            <a v-text="'See link'" :href="row.item.url" target="_blank" />-->
<!--                        </template>-->

<!--                        <template v-slot:cell(link)="row">-->
<!--                            <a style="text-decoration: none" @click.prevent href="#" v-b-popover.hover.html.right="imageInfo(row.item)" :title=" 'Client Image'"><img width="32" v-lazy="`${$root.serverUrl}/admin/download?url=${encodeURI(row.item.file)}`"></a>-->
<!--                        </template>-->

<!--                        <template v-slot:cell(info)="row">-->
<!--                            <p class="m-0 p-0">{{ row.item.info.registrar }} <i class="fa fa-info-circle" style="color: #007bff" v-b-popover.hover.html.top="domainInfo(row.item.info)" /></p>-->
<!--                        </template>-->

<!--                        <template v-slot:cell(workflow_id)="row">-->
<!--                            <a @click.prevent href="#" style="text-decoration: none" v-b-popover.hover.html.right="workflowInfo(row.item.workflow)" title="Workflow Summary">{{ row.item.workflow_id }} </a>-->
<!--                        </template>-->

<!--                        <template v-slot:cell(actions)="row">-->
<!--                            <b-button variant="info" size="sm" @click="row_item = Object.assign({info: {}, workflow: {}}, row.item)" v-b-modal.result_row v-b-tooltip.top title="Edit Result">Details</b-button>-->
<!--                        </template>-->

<!--                    </b-table>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row mb-6">-->
<!--                <div class="col-11 col-md-11 d-flex justify-content-center">-->
<!--                    <b-pagination-->
<!--                        v-model="currentPage"-->
<!--                        :total-rows="rows"-->
<!--                        :per-page="perPage"-->
<!--                    ></b-pagination>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
// import Result from '../components/workflow/result'
import result_details from '../components/workflow/result-details'
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import axios from "axios";

Vue.use( VueLazyload, {
    preLoad: 1.3,
    loading: '/loading.gif',
    attempt: 1
})

export default {
    name: "WorkflowResult",
    props: ['user'],
    data() {
        return {
            currentPage: 1,
            rows: 0,
            perPage: 25,
            resMngt: 'old',
            runtimes: [{ value: null, text: 'Run Time' }],
            clientnames: [{ value: null, text: 'Client Name' }],
            productnames: [{ value: null, text: 'Product Type' }],
            plantypes: [{ value: null, text: 'Plan Type' }],
            results: [],
            workflows: [],
            details: '',
            row_item: { },
            items: {},
            // items: [
            //     // { result_sets: 'Day 1', start_date: '07/11/2021', score: '100', total_checks: 4, positive_checks: 4, auto_resp: 3, url_id: 1, url: 'bjones.com/widget.html', dos: '07/11/2021', tos: '16:47:09', confidence_score: 7.6, screen_links: 'https://tinyurl.com/4534543', registrar_phone: '344-134-2345', registrar_email: 'bjones@example.com', whois_link: 'https://who.is/whois/bjones.com', hosting: 'GoDaddy', hosting_email: 'support@godaddy.com', times_iden: 5, last_time_iden: '16:52:01', c_d: 'Yes/No' },
            //     // { result_sets: 'Day 2', start_date: '07/12/2021', score: '10', total_checks: 4, positive_checks: 4, auto_resp: 3, url_id: 1, url: 'bjones.com/widget.html', dos: '07/11/2021', tos: '17:47:09', confidence_score: 7.6, screen_links: 'https://tinyurl.com/4534543', registrar_phone: '344-134-9845', registrar_email: 'bjones@example.com', whois_link: 'https://who.is/whois/bjones.com', hosting: 'GoDaddy', hosting_email: 'support@godaddy.com', times_iden: 5, last_time_iden: '16:52:01', c_d: 'Yes/No' },
            //     // { result_sets: 'Day 3', start_date: '07/13/2021', score: '90', total_checks: 5, positive_checks: 9, auto_resp: 3, url_id: 1, url: 'bjones.com/widget.html', dos: '07/14/2021', tos: '19:47:09', confidence_score: 7.6, screen_links: 'https://tinyurl.com/4534543', registrar_phone: '344-139-2345', registrar_email: 'bjones@example.com', whois_link: 'https://who.is/whois/bjones.com', hosting: 'GoDaddy', hosting_email: 'support@godaddy.com', times_iden: 5, last_time_iden: '16:52:01', c_d: 'Yes/No' }
            // ],
        }
    },
    components: {
        // Result,
        result_details },
    created() {
        this.getResults()
    },
    computed: {
        resultFields() {
            return [
                {
                    key: 'day',
                    label: 'Date',
                    sortable: false
                },
                {
                    key: 'scans',
                    label: 'Total Checks',
                    sortable: false
                },
                {
                    key: 'matches',
                    label: 'Positive Checks',
                    sortable: false
                },
                {
                    key: 'pass',
                    label: 'Auto Pass Responses',
                    sortable: false
                },
                {
                    key: 'fail',
                    label: 'Auto Fail Responses',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: false
                },
            ]
        },
        demoFields() {
            return [
                {
                    key: 'client_id',
                    label: 'Client ID',
                    sortable: false
                },
                {
                    key: 'content_id',
                    label: 'Content ID',
                    sortable: false
                },
                {
                    key: 'workflow_id',
                    label: 'Workflow',
                    sortable: false
                },
                {
                    key: 'link',
                    label: 'Image',
                    sortable: false
                },
                {
                    key: 'score',
                    label: 'Score',
                    sortable: false
                },
                {
                    key: 'info',
                    label: 'Domain Info',
                    sortable: false
                },
                {
                    key: 'url',
                    label: 'Discovered URL',
                    sortable: false
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: false
                },
            ]
        },
    },
    watch: {
        currentPage() {
            this.getResults()
        }
    },
    methods: {
        getResults() {
            if ( this.resMngt === 'old' ) {
                axios.get(`${this.$root.serverUrl}/admin/workflowConfig/dailysummary/${this.$route.query.config_id}`, { params: { page: this.currentPage, perPage: this.perPage } }).then((resp) => {
                    this.items = { }
                    this.items = Object.assign({}, resp.data.data)
                    this.rows = resp.data.data.total
                    this.$root.preloader = false;
                })
            }
            if ( this.resMngt === 'demo' ) {
                axios.get(`${this.$root.serverUrl}/admin/results/${this.$route.query.config_id}`).then((resp) => {
                    this.results = resp.data.map((data) => {
                        data.score = data.score.toFixed(2)
                        return data
                    })
                    this.$root.preloader = false;
                })
            }
        },
        exportDetails(date, name) {
            let new_date =  date.substr(0, 10)

            if(this.$route.query.config_id) {
                this.$bvToast.toast(`Result Export - ${name}`, {
                    title: `Generating excel sheet for ${date.substr(0, 10)}.`,
                    toaster: 'b-toaster-top-full',
                    variant: 'info',
                    solid: true
                })

                axios.get(`${this.$root.serverUrl}/admin/workflowConfig/export/dailysummary/${this.$route.query.config_id}`, {responseType: 'blob'} ).then((resp) => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${name}_${new_date}.xlsx`);
                    link.click();
                }).catch((err) => {
                    this.$bvToast.toast(`Result Export - ${name}`, {
                        title: `Error generating report - ${err}`,
                        toaster: 'b-toaster-top-full',
                        variant: 'danger',
                        solid: true
                    })
                })
            }
        },
        sendDetails(row) {
           let date = row.day.substr(0, 10)
            this.details = date.toString().trim()
            this.$bvModal.show('result_details')
        },
        imageInfo(obj) {
            return `<img width="350" src="${this.$root.serverUrl}/admin/download?url=${encodeURI(obj.file)}">`
        },
        widgetInfo(array) {
            for (let item of array) {
                return `<strong>` + `${item.widget} Widget` + `</strong>`+
                    `<hr />` +
                    `<strong>` + `Min Confidence Score: ` + `</strong>` + `${item.widget_options.min_confidence_score}` + `<br />` +
                    `<strong>` + `Max Confidence Score: ` + `</strong>` + `${item.widget_options.max_confidence_score}`
            }
        },
        workflowInfo(obj) {
            return `<strong>` + `Worflow Name:` + `</strong>` + ` ${obj.name}` +
                `<br /><strong>` + `Run Time:` + `</strong>` + ` ${obj.runTime}` +
                `<br /><strong>` + `Workflow Type:` + `</strong>` + ` ${obj.type}` +
                `<br /><strong>` + `Min Passing Score:` + `</strong>` + ` ${obj.minScore}` +
                `<br /><strong>` + `Max Passing Score:` + `</strong>` + ` ${obj.maxScore}`
        },
        domainInfo(obj) {
            return `<strong>` + `Address:` + `</strong>` + ` ${obj.address}, ${obj.city}, ${obj.state}` + `<br /><br />` +
                `<strong>` +`Emails:`+ `</strong>` + `${obj.emails}`
        }
    },
}
</script>

<style scoped>
.popover {
    max-width: 1000px !important;
}
</style>
