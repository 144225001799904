<template>
    <div>
        <b-modal
            id="result_details"
            size="xl"
            header-class="colorz"
            :hide-footer="true"
            centered
        >

            <template #modal-header>
                <h5 class="text-white m-0">Content Shield Result Details</h5>
                <div class="float-right">
                    <i class="fa fa-times" size="sm" @click="$bvModal.hide('result_details')" style="cursor: pointer"/>
                </div>
            </template>

            <b-table
                sticky-header="500px"
                head-variant="dark"
                :items="details"
                :fields="fields"
                :current-page="currentPage"
            >

                <template v-slot:cell(id)="row">
                    <div>{{ row.index + 1 }}</div>
                </template>

                <template v-slot:cell(url)="row">
                    <a v-text="'See link'" :href="resUrl(row.item.url)" target="_blank" />
                </template>

                <template v-slot:cell(_id)="row">
                    <a v-text="'See link'" :href="row.item.url" target="_blank" />
                </template>

                <template v-slot:cell(content_date)="row">
                    <div>{{ row.item.content_date|userTime(user.timezone) }}</div>
                </template>

                <template v-slot:cell(created_ts)="row">
                    <div>{{ row.item.created_ts|userTime(user.timezone) }}</div>
                </template>

                <template v-slot:cell(score)="row">
                    <div>{{ row.item.score }}%</div>
                </template>

                <template v-slot:cell(result)="row">
                    <div v-for="item in row.item.work_flow_results" :key="item.key">
                        <div v-bind:class="{ 'img-score': (item.key === 'img-widget'), 'img-score': (item.key !== 'img-widget'), 'pass': (item.confidence >= score(item.key)), fail: (item.confidence < score(item.key)) }">
                            {{ item.key === "img-widget" ? "Image Score: " + item.confidence.toFixed(0) : "Text Score: " + item.confidence.toFixed(0) }}%</div>
                    </div>
                </template>

                <template v-slot:cell(file)="row">
                    <a style="text-decoration: none" @click.prevent href="#" v-b-popover.hover.html.right="imageInfo(row.item)"><img width="64" v-lazy="`${$root.serverUrl}/admin/download?url=${encodeURI(row.item.file)}`"></a>
                </template>

            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="rowTotal"
                :per-page="perPage"
            ></b-pagination>

        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import VueLazyload from "vue-lazyload";

Vue.use( VueLazyload, {
    preLoad: 1.3,
    loading: '/loading.gif',
    attempt: 1
})

export default {
    name: "result-details",
    data() {
      return {
          perPage: 25,
          rowTotal: 0,
          currentPage: 1,
          details: [],
      }
    },
    props: {
        user: {
          type: Object
        },
        steps: {
            type: Array
        },
        date: {
            type: String,
            required: true
        }
    },
    watch: {
      date() {
          this.getDetails()
      },
      currentPage() {
          this.getDetails()
      }
    },
    computed: {
      fields() {
          return [
              {
                  key: 'id',
                  label: 'ID',
                  sortable: false
              },
              {
                  key: 'file',
                  label: 'Result Sample',
                  sortable: false
              },
              {
                  key: 'result',
                  label: 'Widget Results',
                  sortable: false
              },
              {
                  key: 'content_date',
                  label: 'Date Collected',
                  sortable: false
              },
              {
                  key: 'created_ts',
                  label: 'Date Analyzed',
                  sortable: false
              },
              {
                  key: '_id',
                  label: 'Source URL',
                  sortable: false
              },
              {
                  key: 'url',
                  label: 'WHOIS URL',
                  sortable: false
              },
              {
                  key: 'score',
                  label: 'Confidence Score',
                  sortable: false
              },
          ]
      }
    },
    methods: {
        imageInfo(obj) {
            return `<img style="position: relative;" width="400" src="${this.$root.serverUrl}/admin/download?url=${encodeURI(obj.file)}">`
        },
        resUrl(url) {
          let domain = new URL(url)
          return `https://who.is/whois/${domain.hostname}`
        },
        score(key) {
            if(!this.steps || !this.steps.length) return
            for(let i = 0; i < this.steps.length; i++) {
                if(key === this.steps[i].key) return this.steps[i].widget_options.min_confidence_score
            }
        },
        getDetails() {
            axios.get(`${this.$root.serverUrl}/admin/workflowConfig/daily/${this.$route.query.config_id}`, { params: { page: this.currentPage, date: this.date, perPage: this.perPage } }).then((resp) => {
                this.$nextTick(() => {
                    this.details = resp.data.data.result
                    this.rowTotal = resp.data.data.total
                })
            })
        }
    }
}
</script>

<style scoped>
.popover {
    width: 400px !important;
}
.txt-score {
    margin-top: 10px;
}
.img-score {
    margin-top: 5px;
}
.fail {
    background: #e90606;
    color: white;
    text-align: center;
    border-radius: 3px;
    font-weight: 500;
}
.pass {
    background: #40bd40;
    color: white;
    text-align: center;
    border-radius: 3px;
    font-weight: 500;

}
/deep/ .colorz {
    background-color: #444444;
    color: white;
}
/*/deep/ .modal-content {*/
/*    width: auto !important;*/
/*    border: none !important;*/
/*    box-shadow: none !important;*/
/*}*/
/*/deep/ .modal-body {*/
/*    height: 500px !important;*/
/*}*/
/*/deep/ .modal-dialog-centered {*/
/*    justify-content: center;*/
/*}*/
</style>
